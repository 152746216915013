import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { logo, title } from '../config/Config'
import { Facebook, Instagram, Telegram, Twitter } from '@mui/icons-material'


const Footer = () => {
  return (
    <footer id='footer-section'>
      <div className='__custom_container'>
        <Row>
          <Col sm={12}>
            <div className='__leftFooter'>
              <Link to='/'>
                <img src={logo} alt={title + "logo"} />
              </Link>

              <p>AI Alpha Trade is one such crypto platform, which is the best platform to secure</p>

              <div className="d-flex justify-content-center">
                <Link to="https://www.facebook.com/" className="nav-link facebook" target="_blank"> <div className='__linkIcon'> <Facebook /></div>  </Link>
                <Link to="https://twitter.com/" className="nav-link XIcon" target="_blank">  <div className='__linkIcon'><Twitter />  </div> </Link>
                <Link to="https://www.instagram.com/" className="nav-link Instagram" target="_blank">  <div className='__linkIcon'> <Instagram /></div>  </Link>
                <Link to="https://web.telegram.org/" className="nav-link Telegram" target="_blank">  <div className='__linkIcon'> <Telegram /> </div>  </Link>
              </div>
            </div>
          </Col>


          <Col sm={12}>
            <div className='__center'>
              {/* <h5> Service</h5> */}
              <ul className='nav'>
                <li className='nav-item'>
                  <Link to='#home' className='nav-link'> Home</Link>
                </li>

                <li className='nav-item'>
                  <Link to='/about' className='nav-link'> Product</Link>
                </li>

                <li className='nav-item'>
                  <Link to='/team' className='nav-link'> Team</Link>
                </li>

                <li className='nav-item'>
                  <Link to='/roadmap' className='nav-link'> Roadmap</Link>
                </li>


                <li className='nav-item'>
                  <Link to='/our-services' className='nav-link'> Our Services</Link>
                </li>

                <li className='nav-item'>
                  <Link to='/contact-us' className='nav-link'> Contact Us</Link>
                </li>
              </ul>
            </div>
          </Col>

          {/* <Col lg={4}>
            <div className='__center'>
              <h5> Products</h5>
              <ul className='nav'>
                <li className='nav-item'>
                  <Link to='#meta' className='nav-link'> Home</Link>
                </li>

                <li className='nav-item'>
                  <Link to='#' className='nav-link'> Product</Link>
                </li>

                <li className='nav-item'>
                  <Link to='#' className='nav-link'> Features</Link>
                </li>

                <li className='nav-item'>
                  <Link to='#' className='nav-link'> Token</Link>
                </li>

                <li className='nav-item'>
                  <Link to='#' className='nav-link'> Contact</Link>
                </li>
              </ul>
            </div>
          </Col> */}


        </Row>

        <div className='__copyRyt'>
          <p>Copyright © 2024 {title}. All Rights Reserved.</p>
        </div>
      </div>


    </footer>
  )
}

export default Footer